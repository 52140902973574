// src/app/InventoryPage/Inventory.js
import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Button,
  ButtonGroup,
  Box,
  Card,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { cx } from "../../utils/cx.js";
import { useGlobalOrder } from "../../utils/GlobalStateContext.jsx";
import { useLocation } from "react-router-dom";
import { getFoods, changeFoodStatus } from "../../utils/ordersControlar.js";
const orderTypes = [
  { label: "全部", value: 0 },
  { label: "正常", value: 1 },
  { label: "暫停", value: 2 },
  { label: "停售", value: 3 },
];

export default function Inventory() {
  const { foodData, setFoodData } = useGlobalOrder();
  const debounceTimeout = useRef(null);
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filterFood, setFilterFood] = useState(0);
  const [selectedFood, setSelectedFood] = useState(null);
  const fetchData = async () => {
    const data = await getFoods();
    setFoodData(data);
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    // 設置一個新的 timeout 來延遲執行 fetchData
    debounceTimeout.current = setTimeout(() => {
      fetchData();
    }, 500);
    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, []);
  const changeStatus = async (status) => {
    try {
      await changeFoodStatus({
        name: selectedFood.name,
        status: status,
      });
      await fetchData();
    } catch (error) {
      console.error("Failed to change status:", error);
    }
  };

  const filteredMenuItems = () => {
    const foods = foodData?.detail?.filter(
      (item) => item.category_id === selectedCategory
    );
    const filterFoods = foods.filter((food) => food.status === filterFood);
    return filterFood > 0 ? [...filterFoods] : [...foods];
  };
  const renderMenu = () => {
    const handleCategoryChange = (category) => {
      setSelectedCategory(category);
      setSelectedFood(null);
    };

    return foodData?.category?.map((menu) => {
      let itemCount = [] ;
      itemCount[0] = foodData?.detail?.filter(
        (item) => item.category_id === menu.category_id
      ).length;

      //正常
        itemCount[1] = foodData?.detail?.filter(
            (item) => item.category_id === menu.category_id && item.status === 1
        ).length;
        //暫停銷售
        itemCount[2] = foodData?.detail?.filter(
            (item) => item.category_id === menu.category_id && item.status === 2
        ).length;
        //停止銷售
        itemCount[3] = foodData?.detail?.filter(
            (item) => item.category_id === menu.category_id && item.status === 3
        ).length;

        if(itemCount[filterFood] === 0)
            return '' ;

      return (
        <Grid
          item
          key={`menu_${menu.category_id}`}
          xs={1.5}
          className="max-h-[100px]"
        >
          <Card
            className="w-full max-h-[100px] relative"
            sx={{
              aspectRatio: "1/1",
              boxShadow:
                selectedCategory === menu.category_id
                  ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                  : "none",
              backgroundColor:
                selectedCategory === menu.category_id ? "#FFF" : "#8E8E8E",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 4,
              height: "100%",
            }}
          >
            {/* 類別的數量標籤 */}
            {
              <Box
                sx={{
                  position: "absolute",
                  top: 1,
                  right: 1,
                  backgroundColor: "#aaaaaa",
                  color: "#FFFFFF",
                  borderRadius: "50%",
                  width: 32,
                  height: 32,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 700,
                }}
              >
                {itemCount[filterFood]}
              </Box>
            }
            <CardActionArea
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleCategoryChange(menu.category_id)}
            >
              <CardContent
                sx={{
                  padding: 1,
                  paddingY: "16px !important",
                }}
              >
                <span
                  style={{
                    fontWeight:
                      selectedCategory === menu.category_id ? 700 : 400,
                    fontSize: 20,
                    letterSpacing: 4,
                    color:
                      selectedCategory === menu.category_id
                        ? "#A43B3B"
                        : "#E2E2E2",
                  }}
                >
                  <p
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {menu.name}
                  </p>
                </span>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      );
    });
  };

  const renderItems = () => {
    const items = filteredMenuItems();
    return items?.map((item, index) => (
      <Grid item key={item.name + "_" + index} xs={1.5} sx={{ flexGrow: 1 }}>
        <Card
          sx={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            backgroundColor:
              item.status === 3
                ? "#8E8E8E"
                : item.status === 2
                ? "#3C3C3C"
                : "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 4,
          }}
        >
          <CardActionArea
            sx={{
              height: "100%", // 讓CardActionArea佔滿Card的高度
              width: "100%", // 讓CardActionArea佔滿Card的寬度
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() =>
              changeFoodStatus({
                name: item.name,
                status: item.status === 1 ? 2 : item.status === 2 ? 3 : 1,
              }).then(() => {
                fetchData();
              })
            }
          >
            <CardContent
              sx={{
                height: 143,
                overflow: "hidden", // 控制內容不超出範圍
              }}
              onClick={() => {
                setSelectedFood(item);
              }}
            >
              <span
                className={cx(
                  `text_default_style_b grid  ${
                    item.status === 2
                      ? "text-[#FFF]"
                      : item.status === 3
                      ? "text-sold_out_gray"
                      : "#000"
                  }`
                )}
              >
                <p
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2, // 限制顯示兩行
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.name}
                </p>
                <p className="text-[20px] mt-[8px]">
                  {item.status === 3
                    ? "停售"
                    : item.status === 2
                    ? "暫停"
                    : "正常"}
                </p>
              </span>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    ));
  };

  return (
    foodData && (
      <Grid
        container
        direction="column"
        sx={{ backgroundColor: "#f0f0f0", height: "100vh" }}
      >
        {/* 上方ButtonGroup区域，用于选择订单类型 */}
        <Grid
          item
          sx={{
            marginX: "0px !important",
            width: "100%",
            overflow: "auto",
            height: 80,
            display: "flex",
            alignItems: "center",
            padding: "0px 16px",
            gap: "16px",
          }}
          className="hide-scrollbar"
        >
          <ButtonGroup
            sx={{
              overflow: "hidden",
              height: "48px",
            }}
            aria-label="Order type selection"
          >
            {orderTypes.map((type) => (
              <Button
                key={type.value}
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  px: 3,
                  border: "1px solid #8E8E8E",
                  borderRadius: 4,
                  borderColor: filterFood === type.value ? "#FFF" : "#8E8E8E",
                  backgroundColor:
                    filterFood === type.value ? "#FFF" : "#8E8E8E",
                  color: filterFood === type.value ? "#A43B3B" : "#FFF",
                }}
                onClick={() => setFilterFood(type.value)}
              >
                <p className="flex min-w-[36px]">{type.label}</p>
              </Button>
            ))}
          </ButtonGroup>
        </Grid>

        {/* 下方区域 */}
        <Grid
          container
          direction="column"
          sx={{
            maxHeight: "calc(100% - 80px)",
            flexGrow: 1,
            display: "flex",
            flexWrap: "nowrap",
            borderTop: "1px solid #797777",
          }}
        >
          <Grid
            container
            sx={{
              gap: 2,
              height: "40%",
              overflowY: "auto",
              flexWrap: "wrap",
              padding: 2,
              justifyContent: "flex-start", // 將卡片靠左對齊
            }}
            className="hide-scrollbar"
          >
            {renderMenu()}
          </Grid>
          <Grid
            container
            sx={{
              border: "none",
              borderBottom: "1px solid #000",
              height: "16px",
              marginBottom: "16px",
            }}
          />
          <Grid
            container
            sx={{
              padding: 2,
              gap: 2,
              height: "calc( 60% - 32px )",
              overflowY: "auto",
              flexWrap: "wrap",
              justifyContent: "flex-start", // 將卡片靠左對齊
            }}
            className="hide-scrollbar"
          >
            {renderItems()}
          </Grid>
        </Grid>
      </Grid>
    )
  );
}

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainMenu from "./app/MainMenu/MainMenu";
import OrderPage from "./app/OrderPage/OrderPage";
import OrderHistory from "./app/OrderHistory/OrderHistory";
import Inventory from "./app/Inventory/Inventory";
import SalesRecords from "./app/Sales_Records/SalesRecords";
import DiningOut from './app/DiningOut/DiningOut';
import Income from './app/Income/Income';
import { GlobalStateProvider } from './utils/GlobalStateContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useRef } from "react";

// 定義 AutoReload 組件
function AutoReload() {
  const idleTimeoutRef = useRef(null);
  const idleTimeLimit = 60 * 60 * 1000; // 設定閒置時間為1個小時

  const resetIdleTimer = () => {
    if (idleTimeoutRef.current) {
      clearTimeout(idleTimeoutRef.current);
    }
    idleTimeoutRef.current = setTimeout(() => {
      window.location.reload(); // 閒置超時後重新整理頁面
    }, idleTimeLimit);
  };

  // 添加監聽器來檢測頁面是否被喚醒（移動裝置待機後喚醒）
  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      window.location.reload(); // 待機後頁面被喚醒時重新整理
    }
  };

  useEffect(() => {
    const events = ["mousemove", "mousedown", "keydown", "scroll", "touchstart"];

    events.forEach((event) => {
      window.addEventListener(event, resetIdleTimer);
    });

    resetIdleTimer(); // 初始化計時器

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetIdleTimer);
      });
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current);
      }
    };
  }, []);
  return null; // 不需要渲染任何內容
}

function App() {
  return (
      <GlobalStateProvider>
        <Router>
          <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              closeOnClick
              draggable
              pauseOnHover
              theme="dark"
          />
          <AutoReload /> {/* 加入 AutoReload 組件 */}
          <Routes>
            <Route path="/" element={<MainMenu />}>
              <Route path="/order" element={<OrderPage />} />
              <Route path="/order-history" element={<OrderHistory />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/sales-records" element={<SalesRecords />} />
              <Route path="/dining-out" element={<DiningOut />} />
              <Route path="/income" element={<Income />} />
            </Route>
          </Routes>
        </Router>
      </GlobalStateProvider>
  );
}

export default App;

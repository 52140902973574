import { useState, useEffect } from "react";
import {getEnvAPI, getOrders} from "../../../utils/ordersControlar";

const useAuthToken = (setTodayOrders , setSystemData) => {
    const [systemLogin, setSystemLogin] = useState(false);
    const [loading, setLoading] = useState(true);

    const checkAuthentication = async () => {
        console.log("test",systemLogin);
        if(systemLogin !== false)
            return ;
        const token = localStorage.getItem("token");
        if (token) {
            const newData = await getEnvAPI() ;
            if(newData?.error === 0){
                localStorage.setItem("token",newData.token) ;  //更新Token
                setSystemData(newData.store);
                setSystemLogin(true);
            }else{
                localStorage.removeItem("token") ;
                setSystemLogin(false);
            }
        } else {
            setSystemLogin(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        checkAuthentication();
    }, [systemLogin]);

    return { systemLogin, loading , setSystemLogin , checkAuthentication };
};

export default useAuthToken;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api_client from "../utils/api_client";

// 初始狀態
const initialState = {
    token: null,
    user: null,
    loading: false,
    error: null,
};

// 登入的異步操作
export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async ({ account, password }, thunkAPI) => {
        try {
            const response = await api_client.post('/login', { account, password });
            // 假設 API 回傳 token 和用戶信息
            if(response.data.error === 0){
                const { token ,store } = response.data;
                // 儲存 token 到 localStorage
                localStorage.setItem('token', token);
            }
            return response.data ;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
);

// authSlice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.token = null;
            state.user = null;
            localStorage.removeItem('token'); // 移除 token
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.token = action.payload.token;
                state.user = action.payload.user;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;

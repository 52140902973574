// CreditCard.jsx
import React, { useState } from "react";
import { Button, Grid, Box } from "@mui/material";

const CreditCard = (props) => {
  const [complete, setComplete] = useState(false);
  const { clickCheckOut, unpaid_amount } = props;
  //callAPI???
  const handlePayment = () => {
    // 在這裡處理付款邏輯
    clickCheckOut({});
    //支付成功
    unpaid_amount === 0 && setComplete(true);
  };
  return !complete ? (
    <div>
      <h1 className="system_login_title text-[28px] text-dark_red flex items-center justify-center h-full w-full">
        信用卡扣款
      </h1>
      <Grid container sx={{ marginTop: 2, fontWeight: "bold", width: "100%" }}>
        <Button
          variant="contained"
          fullWidth
          size="large"
          sx={{
            backgroundColor: "#FFF",
            height: "60px",
            borderRadius: 2,
            padding: "8px 16px",
          }}
          onClick={handlePayment}
        >
          <p className="text_default_style text-dark_red font-[700]">扣款</p>
        </Button>
      </Grid>
    </div>
  ) : (
    <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
      結帳完成
    </h1>
  );
};

export default CreditCard;

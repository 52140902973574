import React, { useEffect, useState } from "react";

const Timer = ({ dateTime }) => {
  const [timeElapsed, setTimeElapsed] = useState("");

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date();
      // 將 dateTime 轉換為 JavaScript Date 對象
      const orderDate = new Date(dateTime.replace(" ", "T")); // 使用 T 分隔日期和時間
      const diff = Math.floor((now - orderDate) / 1000); // 計算秒數差
      const hours = Math.floor(diff / 3600); // 小時
      const minutes = Math.floor((diff % 3600) / 60); // 分鐘
      const seconds = diff % 60; // 秒
      if(hours){
        setTimeElapsed(`${hours}:${minutes}:${seconds}`); // 格式化顯示
      }else{
        setTimeElapsed(`${minutes}:${seconds}`); // 格式化顯示
      }
    };

    const timerId = setInterval(updateTimer, 1000);
    return () => clearInterval(timerId);
  }, [dateTime]);

  return <span>{timeElapsed}</span>;
};

export default Timer;

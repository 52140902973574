import React, { useState } from "react";
import { Button, TextField, Grid, Box } from "@mui/material";

export default function Cash(props) {
  const { back, setValue, clickCheckOut, getValues } = props;
  const [amount, setAmount] = useState(0);
  const [complete, setComplete] = useState(false);

  // 處理數字鍵盤按鈕點擊事件
  const handleButtonClick = (value) => {
    setAmount((prevAmount) => {
      const newAmount = `${prevAmount}${value}`; // 拼接字串
      return Number(newAmount); // 轉為數字
    });
  };

  // 處理付款邏輯
  const handlePayment = () => {
    clickCheckOut({
      cash_money: Number(amount), // 傳遞數字值
    });

    back();
    setComplete(true); // 設置為完成狀態
  };

  // 清除數字
  const handleClear = () => {
    setAmount(0); // 清除為 0
  };

  return !complete ? (
    <Box sx={{ paddingX: 2, height: "100%" }}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom: 2,
        }}
      >
        <p className="min-w-[80px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
          現金
        </p>
        <TextField
          variant="outlined"
          value={amount}
          fullWidth
          disabled
          InputProps={{
            sx: {
              borderRadius: 4,
              fontSize: "20px",
              color: "#797777",
              fontWeight: 700,
              backgroundColor: "#FFF",
              "&::placeholder": {
                color: "#C0C0C0",
              },
            },
          }}
          sx={{
            borderRadius: 4,
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
          }}
        />
      </Grid>

      {/* 數字鍵盤 */}
      <Grid
        container
        spacing={1}
        sx={{ display: "flex", flexWrap: "wrap-reverse" }}
      >
        {Array.from({ length: 10 }, (_, index) => (
          <Grid item xs={index === 0 ? 12 : 4} key={index}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => handleButtonClick(index)}
              sx={{
                height: 60,
                backgroundColor: "#8E8E8E",
                border: "none",
                borderRadius: 2,
                color: "#FFF",
                fontSize: "24px",
              }}
            >
              {index}
            </Button>
          </Grid>
        ))}
      </Grid>

      <Grid container sx={{ marginTop: 2, fontWeight: "bold" }} spacing={2}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={{
              backgroundColor: "#FFF",
              height: "60px",
              borderRadius: 2,
              padding: "8px 16px",
            }}
            onClick={handleClear}
          >
            <p className="text_default_style text-[#000] font-[700]"> 清除</p>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={{
              backgroundColor: "#FFF",
              height: "60px",
              borderRadius: 2,
              padding: "8px 16px",
            }}
            onClick={handlePayment}
          >
            <p className="text_default_style text-dark_red font-[700]"> 付款</p>
          </Button>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
      結帳完成
    </h1>
  );
}

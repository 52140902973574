import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Card,
  CardContent,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { cx } from "../../utils/cx.js";
function Commodity(props) {
  const {
    open,
    handleClose,
    commodityData,
    systemData,
    buyCartItem,
    setBuyCartItem,
    openCommodity,
    submitOrder,
    fields,
    append,
    update,
    remove,
  } = props;

  const [quantity, setQuantity] = useState(1);
  const [notes, setNotes] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  useEffect(() => {
    setQuantity(buyCartItem?.qty || 1);
    setNotes(buyCartItem?.note || "");
  }, [open]);
  // 點擊按鈕後處理選擇
  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };
  const handleIncrease = () => setQuantity(quantity + 1);
  const handleDecrease = () => setQuantity(quantity > 0 ? quantity - 1 : 0);
  const handleQuantityChange = (event) => {
    const value = event.target.value;
    const parsedValue = Number(value);

    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setQuantity(parsedValue);
    }
  };

  // 用於存儲每張卡片的點擊計數
  const [timeoutIds, setTimeoutIds] = useState({}); // 用於管理每張卡片的計時器
  const handleCardClick = (item) => {
    const categoryId = item.category_id;
    const food_id = item.pd_id;
    const type = systemData.menu.find(
      (menuItem) => menuItem.category_id === categoryId
    ).type;

    // 清除計時器的邏輯
    if (timeoutIds[categoryId]) {
      clearTimeout(timeoutIds[categoryId]);
      setTimeoutIds((prev) => ({ ...prev, [categoryId]: null }));

      // 雙擊刪除邏輯
      setBuyCartItem((prevBuyCart) => {
        return {
          ...prevBuyCart, // Spread the previous state to keep other properties
          detail: prevBuyCart.detail.filter(
            (commodity) => commodity.food_id !== food_id
          ),
        };
      });
    } else {
      const id = setTimeout(() => {
        setBuyCartItem((prevBuyCart) => {
          if (type === "radio") {
            const foodItemCategory = systemData.food.find(
              (food) => food.pd_id === food_id
            );
            const categoryId = foodItemCategory
              ? foodItemCategory.category_id
              : null;

            if (!categoryId) {
              console.error("Cannot find the category for the new food item");
              return prevBuyCart;
            }

            // 檢查是否有相同分類的 food_id
            const existingCount = prevBuyCart.detail.find((commodity) => {
              const relatedFood = systemData.food.find(
                (food) => food.pd_id === commodity.food_id
              );
              return relatedFood && relatedFood.category_id === categoryId;
            });

            if (existingCount) {
              // 如果有相同 category_id，則更新該商品
              return {
                ...prevBuyCart,
                detail: prevBuyCart.detail.map((commodity) => {
                  const relatedFood = systemData.food.find(
                    (food) => food.pd_id === commodity.food_id
                  );
                  return relatedFood && relatedFood.category_id === categoryId
                    ? { oi_id: commodity.oi_id, food_id: food_id, qty: 1 }
                    : commodity;
                }),
              };
            } else {
              // 如果沒有相同 category_id，則新增該商品
              return {
                ...prevBuyCart,
                detail: [
                  ...prevBuyCart.detail,
                  { oi_id: Date.now(), food_id: food_id, qty: 1 },
                ],
              };
            }
          } else if (type === "selected") {
            // selected: support multiple selection with quantities
            const existingCount = prevBuyCart.detail.find(
              (commodity) => commodity.food_id === food_id
            );

            if (existingCount) {
              // Increment quantity if the commodity already exists
              return {
                ...prevBuyCart,
                detail: prevBuyCart.detail.map((commodity) =>
                  commodity.food_id === food_id
                    ? {
                        ...commodity,
                        qty: existingCount.qty + 1,
                        oi_id: commodity.oi_id,
                      }
                    : commodity
                ),
              };
            } else {
              // Add the new commodity with quantity 1
              return {
                ...prevBuyCart,
                detail: [
                  ...prevBuyCart.detail,
                  { oi_id: Date.now(), food_id, qty: 1 },
                ],
              };
            }
          } else if (type === "checkbox") {
            // checkbox: multi-selection with fixed quantity of 1
            const existingCount = prevBuyCart.detail.find(
              (commodity) => commodity.food_id === food_id
            );

            if (!existingCount) {
              // Add the commodity only if it doesn't exist
              return {
                ...prevBuyCart,
                detail: [
                  ...prevBuyCart.detail,
                  { oi_id: Date.now(), food_id, qty: 1 },
                ],
              };
            }
            // No change if commodity already exists
          }
          return prevBuyCart; // If no condition is met, return the original state
        });
        setTimeoutIds((prev) => ({ ...prev, [categoryId]: null }));
      }, 300);
      setTimeoutIds((prev) => ({ ...prev, [categoryId]: id }));
    }
  };

  const renderContent = () => {
    if (!selectedCategory ) {
      return (
        <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
          請選擇一個類別
        </h1>
      );
      //&& buyCartItem.print !== 1
    // } else if (buyCartItem.print === 1) {
    //   return (
    //     <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
    //       無法修改，是否刪除品項?
    //     </h1>
    //   );
    }

    const filteredFood = systemData.food
      .filter((food) => food.category_id === selectedCategory)
      .sort((a, b) => a.status - b.status);

    return (
      <Grid container direction={"row"} sx={{ gap: "16px" }}>
        {filteredFood.map((item) => (
          <Grid
            item
            key={item.pd_id} // 使用 pd_id 作為鍵以確保唯一性
            xs={2.2}
            className="max-h-[143px]"
            sx={{
              pointerEvents: item.status === 2 ? "none" : "auto",
              cursor: item.status === 2 ? "not-allowed" : "pointer",
              position: "relative",
              ...(buyCartItem?.detail?.find(
                (commodity) => commodity.food_id === item.pd_id
              ) && {
                ".card": {
                  backgroundColor: item.status === 2 ? undefined : "#FFF",
                },
                ".text-content": {
                  color: item.status === 2 ? undefined : "#AC0000",
                  fontWeight: item.status === 2 ? undefined : 700,
                },
              }),
            }}
          >
            <Card
              className="card w-full max-h-[143px]"
              sx={{
                aspectRatio: "1/1",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                backgroundColor: item.status === 2 ? "#3C3C3C" : "#8E8E8E",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 4,
              }}
              onClick={() => handleCardClick(item)}
            >
              <CardContent
                sx={{
                  paddingX: 1,
                  paddingY: "16px !important",
                  maxHeight: 143,
                  transition: "background-color 0.3s ease",
                }}
              >
                <span
                  className={cx(
                    `text_default_style text-content ${
                      item.status === 2
                        ? "text-sold_out_gray"
                        : "text-light_gray"
                    }`
                  )}
                  style={{
                    transition: "color 0.3s ease, font-weight 0.3s ease",
                  }}
                >
                  {item.name}
                  <br />
                  {`$${item.price}`}
                </span>
              </CardContent>

              {/* 右上角顯示點擊次數的計數標籤 */}
              {buyCartItem?.detail.find(
                (commodity) => commodity.food_id === item.pd_id
              ) && (
                <Box
                  sx={{
                    position: "absolute",
                    top: -15,
                    right: -15,
                    backgroundColor: "#AC0000",
                    color: "#FFFFFF",
                    borderRadius: "50%",
                    width: 32,
                    height: 32,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 700,
                  }}
                >
                  {
                    buyCartItem?.detail.find(
                      (commodity) => commodity.food_id === item.pd_id
                    ).qty
                  }
                </Box>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  //計算小類別總價格
  const calculateTotalPrice = () => {
    return buyCartItem?.detail?.reduce((total, item) => {
      const foodData = systemData.food.find(
        (food) => food.pd_id === item.food_id
      );
      const itemPrice = foodData ? foodData.price : 0;
      return total + itemPrice * item.qty;
    }, 0);
  };
  //判斷是否都將必填項完成
  const isValidSubmission = () => {
    // 遍歷套餐中的 composition
    for (const comp of commodityData?.composition) {
      const menuItem = systemData.menu.find(
        (item) => item.category_id === comp.category_id
      );
      const foodItem = systemData.food.filter(
        (item) => item.category_id === menuItem.category_id
      );
      // 如果該類別是必填且在 buyCartItem?.detail 中未找到對應項
      if (menuItem.required === 1) {
        const isMatch = buyCartItem?.detail.some((item) =>
          foodItem.some((food) => food.pd_id === item.food_id)
        );
        if (!isMatch) {
          return false; // 代表有必填項未填寫
        }
      }
    }
    return true; // 所有必填項都已填寫
  };

  const handleBuyCarttUpdated = () => {
    if (openCommodity === "add") {
      if (quantity < 1) {
        resetDialogs();
        return;
      }
      const newItem = {
        ...buyCartItem,
        meal_id: buyCartItem.meal_id,
        qty: quantity,
        note: notes,
        od_id: Date.now(),
      };
      append(newItem);
    } else if (openCommodity === "edit") {
      const existingItemIndex = fields.findIndex(
        (item) => item.od_id === buyCartItem.od_id
      );
      if (existingItemIndex !== -1) {
        if (quantity < 1) {
          remove(existingItemIndex);
          resetDialogs();
        } else {
          update(existingItemIndex, {
            ...fields[existingItemIndex],
            qty: quantity,
            note: notes,
            detail: buyCartItem.detail,
            od_id: buyCartItem.od_id,
          }); // 更新現有項目
        }
      }
    }
    submitOrder();
    resetDialogs();
  };

  const [backdropClickCount, setBackdropClickCount] = useState(0);

  const handleBackdropClick = () => {
    setBackdropClickCount((prevCount) => prevCount + 1);
  };

  const resetDialogs = (event,reason) => {
    if (reason === "backdropClick") {
      handleBackdropClick() ;
      if(backdropClickCount > 0){
        setBackdropClickCount(0);
        handleClose(reason);
        setNotes("");
        setSelectedCategory(null);
        setBuyCartItem((prevBuyCart) => ({
          ...prevBuyCart,
          detail: [], // Clear the detail array
        }));
        setQuantity(1);
      }
    }else{
      handleClose(reason);
      setNotes("");
      setSelectedCategory(null);
      setBuyCartItem((prevBuyCart) => ({
        ...prevBuyCart,
        detail: [], // Clear the detail array
      }));
      setQuantity(1);
    }
  };
  return (
    commodityData &&
    buyCartItem && (
      <Dialog
        open={open}
        onClose={resetDialogs}
        maxWidth="lg"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: 8, // 設定對話框的圓角
            backgroundColor: "#E2E2E2", // 設定對話框背景顏色
            padding: 0,
            height: "80%",
            overflow: "auto",
          },
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          {/* 上方固定高度的部分 */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "80px",
              borderBottom: "1px solid #ccc",
              padding: "16px 32px",
            }}
          >
            <Grid
              item
              xs={4}
              container
              direction={"row"}
              sx={{ gap: "8px", alignItems: "center" }}
            >
              <p className="sidebar_btn_text_b text-sold_out_gray text-left">
                品項
              </p>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#FFF",
                  borderRadius: 4,
                  height: "48px",
                  minWidth: "160px",
                  maxWidth: "220px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingX: "24px",
                }}
              >
                <p
                  className="system_login_text text-[24px] tracking-normal text-[#000] font-[700]"
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1, // 限制顯示兩行
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`${commodityData?.name}`}
                </p>
              </Typography>
            </Grid>
            {( //buyCartItem.print !== 1 ?
              <>
                <Grid
                  item
                  xs={3}
                  container
                  direction={"row"}
                  sx={{ gap: "8px" }}
                >
                  <IconButton
                    onClick={handleDecrease}
                    sx={{
                      padding: "4px",
                      backgroundColor: "#8E8E8E",
                      borderRadius: "9999px",
                      width: "48px",
                      height: "48px",
                      ":hover": {
                        backgroundColor: "#8E8E8E",
                      },
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <TextField
                    variant="outlined"
                    value={quantity}
                    onChange={handleQuantityChange}
                    type="number"
                    sx={{
                      backgroundColor: "#FFF",
                      borderRadius: 4,
                      height: "48px",
                      width: "80px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 4,
                      },
                    }}
                    InputProps={{
                      sx: {
                        marginX: "auto",
                        fontSize: "24px",
                        textAlign: "center",
                        height: "100%",
                        "& input[type='number']": {
                          appearance: "none",
                          MozAppearance: "textfield", // For Firefox
                        },
                        "& input::-webkit-outer-spin-button": {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                        "& input::-webkit-inner-spin-button": {
                          WebkitAppearance: "none",
                          margin: 0,
                        },
                      },
                    }}
                  />
                  <IconButton
                    onClick={handleIncrease}
                    sx={{
                      padding: "4px",
                      backgroundColor: "#8E8E8E",
                      borderRadius: "9999px",
                      width: "48px",
                      height: "48px",
                      ":hover": {
                        backgroundColor: "#8E8E8E",
                      },
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="h6"
                    sx={{
                      backgroundColor: "#FFF",
                      borderRadius: 4,
                      height: "48px",
                      width: "160px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p className="system_login_text text-[#000] font-[400]">
                      {`$${
                        buyCartItem?.detail?.length > 0
                          ? calculateTotalPrice() * quantity +
                            commodityData?.price * quantity
                          : commodityData?.price * quantity
                      }`}
                    </p>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      width: "160px",
                      backgroundColor: "#FFF",
                      height: "48px",
                      borderRadius: "9999px",
                      padding: "8px 16px",
                    }}
                    onClick={() => {
                      if (quantity > 0) {
                        if (isValidSubmission()) {
                          handleBuyCarttUpdated();
                        } else {
                          // 可以在這裡添加提示用戶未填寫必填項的邏輯，例如彈出提示框
                          alert("請確保所有必填項已填寫！");
                        }
                      } else {
                        handleBuyCarttUpdated();
                      }
                    }}
                  >
                    <p className="text_default_style text-dark_red font-[700]">
                      送出
                    </p>
                  </Button>
                </Grid>
              </>
            )
            //   : (
            //   <Grid item xs={2} sx={{ marginLeft: "auto" }}>
            //     <Button
            //       variant="contained"
            //       size="large"
            //       sx={{
            //         width: "160px",
            //         backgroundColor: "#FFF",
            //         height: "48px",
            //         borderRadius: "9999px",
            //         padding: "8px 16px",
            //       }}
            //       onClick={() => {
            //         setQuantity(0);
            //         handleBuyCarttUpdated();
            //       }}
            //     >
            //       <p className="text_default_style text-dark_red font-[700]">
            //         刪除
            //       </p>
            //     </Button>
            //   </Grid>
            // )
            }
          </Box>

          {/* 下方的部分，3:9 的布局 */}
          <Box sx={{ height: "calc(100% - 80px)", overflow: "hidden" }}>
            <Grid container direction={"row"} height={"100%"}>
              {/* 左邊部分，佔 3 */}
              <Grid
                item
                xs={3}
                sx={{
                  borderRight: "1px solid #ccc",
                  position: "relative",
                  height: "100%",
                }}
              >
                <Grid
                  item
                  container
                  direction="column"
                  wrap="nowrap"
                  sx={{
                    padding: "16px",
                    gap: "16px",
                    height: "calc(100% - 100px)", // 剩下的空间用来放按钮
                    overflowY: "auto", // 超出时滚动
                  }}
                  className="hide-scrollbar"
                >
                  {/* 動態生成按鈕 */}
                  {commodityData?.composition && (  //buyCartItem.print !== 1
                    <>
                      {/* Sort composition items based on the requirement */}
                      {commodityData.composition
                        .map((compositionItem) => {
                          // Find corresponding menuItem for each compositionItem
                          const menuItem = systemData.menu.find(
                            (menu) =>
                              menu.category_id === compositionItem.category_id
                          );
                          return { compositionItem, menuItem }; // Return both items for further processing
                        })
                        .sort((a, b) => a.sort - b.sort) // Sort items, prioritizing required ones
                        .map(({ compositionItem, menuItem }) => (
                          <Button
                            key={compositionItem.category_id}
                            variant="outlined"
                            onClick={() =>
                              handleCategoryClick(compositionItem.category_id)
                            }
                            sx={{
                              backgroundColor:
                                selectedCategory === compositionItem.category_id
                                  ? "#FFF"
                                  : "#616161",
                              height: "fit-content",
                              border: "none",
                              borderRadius: 4,
                              display: "grid",
                              justifyContent: "start",
                              padding: "8px 16px",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <span className="flex items-center gap-[16px]">
                              <p
                                className={cx(
                                  "system_login_text text-[24px] tracking-normal font-[700]",
                                  selectedCategory ===
                                    compositionItem.category_id
                                    ? "text-[#000]"
                                    : "text-[#FFF]"
                                )}
                                style={{
                                  maxWidth: "104px",
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 1, // 限制顯示兩行
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {menuItem ? menuItem.name : "未知分類"}
                              </p>
                              <p
                                className={cx(
                                  "system_login_text text-[20px] tracking-normal font-[700] text-dark_red"
                                )}
                              >
                                {menuItem?.required === 1 && "(必填)"}
                              </p>
                            </span>
                            <div className="flex flex-wrap items-center h-fit gap-[8px]">
                              {buyCartItem?.detail
                                .filter((commodity) => {
                                  const foodData = systemData.food.find(
                                    (food) => food.pd_id === commodity.food_id
                                  );
                                  return (
                                    foodData.category_id ===
                                    compositionItem.category_id
                                  );
                                })
                                .map((commodity) => {
                                  const foodData = systemData.food.find(
                                    (food) => food.pd_id === commodity.food_id
                                  );
                                  return (
                                    <span
                                      key={`price_${commodity.food_id}`}
                                      className="p-[8px_16px] flex text-nowrap items-center bg-light_gray rounded-full sidebar_btn_text_b text-[#000] text-[16px]"
                                    >
                                      {`${foodData.name} ${
                                        menuItem.required === 1
                                          ? ""
                                          : "*" + commodity.qty
                                      }`}
                                      {foodData.price !== 0 && (
                                        <span className="">
                                          {foodData.price > 0 ? "+" : ""}
                                          {commodity.qty * foodData.price}
                                        </span>
                                      )}
                                    </span>
                                  );
                                })}
                            </div>
                          </Button>
                        ))}
                    </>
                  )}
                </Grid>

                {/* 備註輸入框 固定在底部 */}
                <Grid
                  item
                  sx={{
                    backgroundColor: "#8E8E8E",
                    height: "100px",
                    position: "absolute",
                    display: "flex",
                    gap: "16px",
                    alignItems: "center",
                    bottom: "0px",
                    width: "100%", // 設定佔滿寬度
                    padding: "16px",
                  }}
                >
                  <p className="min-w-[52px] sidebar_btn_text_b text-[#FFF]">
                    備註
                  </p>
                  <TextField
                    rows={3}
                    variant="outlined"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    InputProps={{
                      sx: {
                        borderRadius: "9999px", // 設定圓角
                        fontSize: "20px",
                        height: "52px",
                        color: "#797777",
                        fontWeight: 700,
                        backgroundColor: "#FFF",
                        "&::placeholder": {
                          color: "#C0C0C0", // 自定义占位符颜色
                        },
                        "&::-webkit-calendar-picker-indicator": {
                          display: "none", // 隐藏日历图标
                        },
                      },
                    }}
                    sx={{
                      height: "52px",
                      borderRadius: "9999px", // 設定圓角
                      backgroundColor: "white", // 設定背景顏色為白色
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none", // 去除邊框
                        },
                        "&:hover fieldset": {
                          border: "none", // 去除懸停時邊框
                        },
                        "&.Mui-focused fieldset": {
                          border: "none", // 去除獲得焦點時邊框
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* 右邊部分，佔 9 */}
              <Grid item xs={9} sx={{ padding: "16px" }}>
                {renderContent()}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    )
  );
}

export default Commodity;

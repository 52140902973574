import { Button, Box, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { cx } from "../../utils/cx";
import {getOrders, printOrder} from "../../utils/ordersControlar.js";

const Sidebar = ({ systemLogin , setTodayOrders , systemData, setSystemLogin, menuItems , currentTime , currentTab , setCurrentTab }) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
       <>
           <Box
               display="flex"
               flexDirection="column"
               sx={{
                   justifyContent: "center",
                   alignItems: "center",
               }}
               p={2}
           >
               <Button
                   className="w-[141px] h-[58px]"
                   key="首頁"
                   fullWidth
                   size="large"
                   onClick={async () => {
                       navigate("/");
                       setCurrentTab(0) ;
                       getOrders({now:1}).then((response)=>{
                           setTodayOrders(response);
                       });
                   }}
                   sx={{ mb: 2 }}
               >
                   <img
                       src={systemData.store_image}
                       alt="logo"
                       className="w-full h-full object-contain"
                   />
               </Button>

               {menuItems.map((item, index) => (
                   <Button
                       className="max-w-[157px] max-h-[125px]"
                       key={item.label}
                       fullWidth
                       size="large"
                       // disabled={!memberLogin}
                       onClick={() => {
                           navigate(item.path);
                           setCurrentTab(null) ;
                           setCurrentTab(item.id) ;
                       }}
                       sx={{
                           mb: 2,
                           display: "grid",
                           borderRadius: 3,
                           backgroundColor:
                               location.pathname === item.path ? "#FFF" : "#616161", // 根據當前路由設定背景顏色
                           gap: 1,
                       }}
                   >
                       <img
                           src={item.image}
                           alt={`left_sidebar_btm_${index}`}
                           className="max-w-[40px] max-h-[40px] object-contain mx-auto"
                       />
                       <p
                           className={cx(
                               `sidebar_btn_text ${
                                   location.pathname === item.path
                                       ? "text-card_dark_red font-[700]"
                                       : "text-light_gray font-[400]"
                               }`
                           )}
                       >
                           {item.label}
                       </p>
                   </Button>
               ))}
           </Box>
           <Box
               display="flex"
               flexDirection="column"
               sx={{
                   justifyContent: "center",
                   alignItems: "center",
               }}
               p={2}
           >
               {systemData.position_id === 3 ? (
                   <Button
                       className="max-w-[157px] max-h-[51px]"
                       key="呼叫櫃檯"
                       fullWidth
                       size="large"
                       onClick={() => {
                           printOrder({orderId: 0, type: 6})
                       }}
                       sx={{
                           mb: 2,
                           backgroundColor: "#616161",
                           borderRadius: 3,
                       }}
                   >
                       <p className="text-light_gray sidebar_btn_text">呼叫櫃檯</p>
                   </Button>
               ) : "" }

               {
                   <Button
                       className="max-w-[157px] max-h-[51px]"
                       key="登出"
                       fullWidth
                       size="large"
                       onClick={() => {
                           localStorage.removeItem("token");
                           setSystemLogin(false);
                           window.location.reload() ;
                       }}
                       sx={{
                           mb: 2,
                           backgroundColor: "#616161",
                           borderRadius: 3,
                       }}
                   >
                       <p className="text-light_gray sidebar_btn_text">登出</p>
                   </Button>
               }
              {/* <Typography
                   sx={{
                       width: "134px",
                       height: "48px",
                       color: "#FFF",
                       fontSize: "20px",
                       lineHeight: "24.2px",
                       textAlign: "center",
                       fontWeight: "400",
                       letterSpacing: 4,
                       marginBottom: 2,
                   }}
                   align="center"
               >
                   {currentTime}
               </Typography>*/}

               {/* {memberLogin && (
            <>
              <Typography
                sx={{
                  width: "134px",
                  color: "#FFF",
                  fontSize: "20px",
                  lineHeight: "24.2px",
                  textAlign: "center",
                  fontWeight: "400",
                  letterSpacing: 4,
                  marginBottom: 2,
                }}
                align="center"
              >
                測試者
              </Typography>
              <Button
                className="max-w-[157px] max-h-[51px]"
                key="登出"
                fullWidth
                size="large"
                onClick={() => {
                  setMemberLogin(false);
                  navigate("/");
                }}
                sx={{
                  backgroundColor: "#616161",
                  borderRadius: 3,
                }}
              >
                <p className="text-light_gray sidebar_btn_text">登出</p>
              </Button>
            </>
          )} */}
           </Box>
       </>
    );
};


export default Sidebar;

import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Button, Grid, Typography,DialogTitle } from "@mui/material";
import { printOrder } from "../../utils/ordersControlar.js";

function Option(props) {
  // 狀態管理，用於切換顯示的內容
  const {
    open,
    handleClose,
    orderId
  } = props;
  const handlePrintOrder = (type : number) =>{
    printOrder({ type:type , orderId:orderId });
  } 
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"md"}
      sx={{
          "& .MuiDialog-paper": {
          borderRadius: 4,
          backgroundColor: "#E2E2E2",
          padding: "32px 24px",
          },
      }}
    >
    <DialogTitle 
        sx={{
        fontSize: "24px", 
        fontWeight: 700, 
        marginBottom: "16px", 
        textAlign: "center"
        }}
    >
        列印選項
    </DialogTitle>
    
    <DialogContent sx={{ padding: 0 }}>
        <Grid item sx={{display: 'flex',gap:'16px'}}>
            <Button
                variant="contained"
                fullWidth
                sx={{
                    backgroundColor: "#FFF",
                    height: "80px",
                    borderRadius: 7,
                    padding: "8px 16px",
                    boxShadow:'unset'
                }}
                onClick={() => {handlePrintOrder(1)}}
                >
                <p className="text_default_style text-[#000] font-[700]">
                    列印明細
                </p>
            </Button>

            <Button
                variant="contained"
                fullWidth
                sx={{
                    backgroundColor: "#FFF",
                    height: "80px",
                    borderRadius: 7,
                    padding: "8px 16px",
                    boxShadow:'unset'
                }}
                onClick={() => {handlePrintOrder(2)}}
                >
                <p className="text_default_style text-[#000] font-[700]">
                    列印標籤
                </p>
            </Button>

            <Button
                variant="contained"
                fullWidth
                sx={{
                    backgroundColor: "#FFF",
                    height: "80px",
                    borderRadius: 7,
                    padding: "8px 16px",
                    boxShadow:'unset'
                }}
                onClick={() => {handlePrintOrder(3)}}
                >
                <p className="text_default_style text-[#000] font-[700]">
                    印廚房單
                </p>
            </Button>
        </Grid>
    </DialogContent>
    </Dialog>

  );
}

export default Option;

// SalesRecords.jsx
import React from "react";
import { DataGrid } from "@mui/x-data-grid";

function SalesRecords() {

  return (
    <div style={{ width: "100%" }}>
      收營盤點
    </div>
  );
}

export default SalesRecords;

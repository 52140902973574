import React, {useEffect, useState} from "react";
import { Grid, Box, Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./sideBar";
import SystemLoginPage from "../SystemLoginPage/SystemLoginPage";
import useAuthToken from "./utils/authToken";
import useCurrentTime from "./utils/currentTime";
import { useGlobalOrder } from "../../utils/GlobalStateContext";

import LeftSidebarOrder from "../../images/order.png";
import LeftSidebarHistory from "../../images/history.png";
import LeftSidebarInventory from "../../images/inventory.png";
import LeftSidebarDiningOut from "../../images/dining_out.png";
import { cx } from "../../utils/cx";
import { orderTypes, statusTable } from "../../utils/status_table";
import { paymentMethods } from "../Dialogs/Payment";
import SalesRecords from "../../images/sales_records.png";
import {getOrders} from "../../utils/ordersControlar";
import OrderRow from "../OrderHistory/OrderRow";

function MainMenu() {
  const { systemData, setSystemData, setGlobalOrder , currentTab , setCurrentTab , todayOrders, setTodayOrders } = useGlobalOrder();
  const navigate = useNavigate(); // 用來導航至其他頁面

  const currentTime = useCurrentTime(); // 使用自定義的時間 hook

  const { systemLogin, loading, setSystemLogin , checkAuthentication } = useAuthToken(
    setTodayOrders,
    setSystemData
  ); // 使用自定義的 token hook

  const menuItems = [
    { id: 1 , label: "點餐", path: "/order", image: LeftSidebarOrder },
    { id: 2 ,label: "訂單記錄", path: "/order-history", image: LeftSidebarHistory },
    { id: 3 ,label: "備料管理", path: "/inventory", image: LeftSidebarInventory },
    { id: 4 ,label: "出餐狀況", path: "/dining-out", image: LeftSidebarDiningOut },
    { id: 5 ,label: "營業狀況", path: "/income", image: SalesRecords },
  ];

  useEffect(  () => {
    if (systemLogin && currentTab === 0) {
      setTodayOrders(null);
      getOrders({now:1}).then((response)=>{
        setTodayOrders(response);
      });

    }
  }, [systemLogin,currentTab]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h4">載入中...</Typography>
      </Box>
    );
  }

  return systemData ? (
    <Grid
      container
      sx={{
        display: "flex",
        flexWrap: "nowrap",
        width: "100%",
        height: "100vh",
      }}
    >
      <Grid
        item
        xs={1.5}
        className="bg-dark_gray h-full flex w-[178px] h-[100vh]"
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Sidebar
          systemData={systemData}
          setSystemLogin={setSystemLogin}
          menuItems={menuItems}
          currentTime={currentTime}
          systemLogin={systemLogin}
          setTodayOrders={setTodayOrders}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </Grid>
      <Grid item xs={10.5} className="bg-light_gray">
        <Box sx={{ height: "100%", width: "100%" }}>
          {/* 右側頁面顯示 */}
          {window.location.pathname === "/" ? (
            <Grid
              item
              xs={10.5}
              className="bg-light_gray h-[100vh]"
              sx={{ width: "100%", maxWidth: "100% !important" }}
            >
              <Box sx={{ height: "100%" }}>
                <Box
                  sx={{
                    display: "grid",
                    width: "100%",
                    gridTemplateRows: "220px auto",
                    height: "100%",
                    gap: "16px",
                  }}
                  p={2}
                  align="center"
                >
                  <div className="w-[328px] h-[140px] mx-auto my-[40px]">
                    <img
                      src={systemData.store_image}
                      alt="logo"
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <Grid
                    container
                    direction={"column"}
                    sx={{
                      gap: "16px",
                      height: "100%",
                      maxWidth: "75%",
                      marginX: "auto",
                      maxHeight: "100%",
                      overflow: "auto",
                      flexWrap: "nowrap",
                    }}
                    className="hide-scrollbar"
                  >
                    {todayOrders?.length
                        ? todayOrders.map((order) => (
                                <Grid
                                    key={order.order_id}
                                    container
                                    direction="row"
                                    sx={{
                                      background: [0, 1, 2].includes(order.status)
                                          ? "#797777"
                                          : "#FFF",
                                      borderRadius: 4,
                                      fontWeight: "bold",
                                      display: "flex",
                                      alignItems: "center",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      navigate("/order");
                                      setGlobalOrder(order);
                                    }}
                                >
                                  <OrderRow order={order} key={order} />
                                </Grid>
                            ))
                        : ( todayOrders === null ? (<font>載入中</font>) : (<font>尚未有新訂單</font>))}
                  </Grid>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Outlet />
          )}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <SystemLoginPage
        checkAuthentication={checkAuthentication}
      setSystemLogin={setSystemLogin}
      setSystemData={setSystemData}
    />
  );
}

export default MainMenu;

import api_client from "./api_client"; // 引入封裝好的 axios 客戶端
import { toast } from "react-toastify";

//取得POS商店資料
export const getEnvAPI = async() => {
  try{
    const response = await api_client.get("/env");
    if(response.data.error === 0){
      return response.data ;
    }
  } catch(error){
    handleError(error);
  }
}

// 獲取訂單 API 函數
export const getOrders = async (params) => {
  try {
    const response = await api_client.get("/order", { params });
    return response.data.list;
  } catch (error) {
    handleError(error);
  }
};

// 提交訂單 API 函數
export const submitOrderAPI = async (orderData, print) => {
  try {
    const response = await api_client.post("/buycart", {
      ...orderData,
      print: print,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// 結帳 API 函數
export const checkoutAPI = async ({ order_id, pay_method, cash_money = 0, line_code = 0 }) => {
  try {
    const response = await api_client.post("/checkout", {
      order_id,
      pay_method,
      cash_money,
      line_code,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// 移除訂單 API 函數
export const removeOrder = async (order_id,msg) => {
  try {
    const response = await api_client.delete(`/order?order_id=${order_id}&msg=${msg}`);
    if (response.data.error === 0 && response.data.msg) {
      toast.success(response.data.msg);
      return response.data;
    } else if(response.data.msg) {
      toast.error(response.data.msg);
    }
  } catch (error) {
    handleError(error);
  }
};

// 獲取食物狀態 API 函數
export const getFoods = async () => {
  try {
    const response = await api_client.get("/food_status");
    if (response.data.error === 0) {
      return response.data;
    }
  } catch (error) {
    handleError(error);
  }
};

// 修改食物狀態 API 函數
export const changeFoodStatus = async ({ name, status }) => {
  try {
    const response = await api_client.post("/food_status", {
      name,
      status,
    });
    if (response.data.error !== 0) {
      toast.error('修改失敗');
    }
  } catch (error) {
    handleError(error);
  }
};

// 打印訂單 API 函數
export const printOrder = async ({ type, orderId }) => {
  try {
    const response = await api_client.get("/print_order", {
      params: {
        order_id: orderId,
        type: type,
      },
    });
    if (response.data.error === 0) {
      toast.success(response.data.msg);
    } else {
      toast.error(response.data.msg);
    }
  } catch (error) {
    handleError(error);
  }
};

// 獲取訂單狀態 API 函數
export const getOrderStatusAPI = async () => {
  try {
    const response = await api_client.get("/order_status");
    // console.log("response",response);
    if (response.data.error === 0) {
      return response.data.list;
    } else {
      toast.error("獲取訂單狀態失敗");
    }
  } catch (error) {
    handleError(error);
  }
  return [] ;
};

export const getIncomeAPI = async() => {
  try{
    const response = await api_client.get("/income_data");
    if(response.data.error === 0){
      return response.data ;
    }
  } catch(error){
    handleError(error);
  }
}

// 更新訂單狀態 API 函數
export const postOrderStatusAPI = async (id, type) => {
  try {
    const response = await api_client.post("/order_status", {
      id,
      type,
    });
    if (response.data.error !== 0) {
      toast.error("更新訂單狀態失敗");
    }
  } catch (error) {
    handleError(error);
  }
};

// 統一的錯誤處理函數
const handleError = (error) => {
  if (error.response) {
    toast.error(error.response.data.message || "伺服器錯誤");
  } else {
    toast.error("網絡錯誤");
  }
  throw error;
};
import {Grid} from "@mui/material";
import {cx} from "../../utils/cx";
import {statusTable} from "../../utils/status_table";
import React from "react";


function OrderRaw({order}){

    return (
        <>
            <Grid item xs={2}>
                <p
                    className={cx(
                        `sidebar_btn_text_b text-left text-center justify-center items-center flex my-[16px] h-[28px],
                      ${
                            [0, 1, 2].includes(order.status)
                                ? "text-[#FFF]"
                                : "text-sold_out_gray"
                        }`
                    )}
                >
                    {order.method_text || "-"}
                </p>
            </Grid>
            <Grid item xs={2}>
                <p
                    className={cx(`sidebar_btn_text_b text-left text-center justify-center items-center flex my-[16px] h-[28px]`)}
                    style={{color:order.status_class}}
                >
                    { order.status_name }
                </p>
            </Grid>
            <Grid item xs={2}>
                <p
                    className={cx(
                        `sidebar_btn_text_b text-left text-center justify-center items-center flex max-w-[144px] my-[16px] h-[28px],
                      ${
                            [0, 1, 2].includes(order.status)
                                ? "text-[#FFF]"
                                : "text-sold_out_gray"
                        }`
                    )}
                    style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1, // 限制顯示兩行
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {order.name || "-"}
                </p>
            </Grid>
            <Grid item xs={2}>
                <p
                    className={cx(
                        `sidebar_btn_text_b text-left text-center justify-center items-center flex my-[16px] h-[28px],
                      ${ order.pay_method === "未付款" ? "text-dark_red" : ([0, 1, 2].includes(order.status)
                            ? "text-[#FFF]"
                            : "text-sold_out_gray")}`
                    )}
                >
                    {order.pay_method}
                </p>
            </Grid>

            <Grid item xs={1}>
                <p
                    className={cx(
                        `sidebar_btn_text_b text-left text-center justify-center items-center flex my-[16px] h-[28px],
                      ${
                            [0, 1, 2].includes(order.status)
                                ? "text-[#FFF]"
                                : "text-sold_out_gray"
                        }`
                    )}
                >
                    ${order.total_price || "-"}
                </p>
            </Grid>
            <Grid item xs={2}>
                <p
                    className={cx(
                        `sidebar_btn_text_b text-left text-center justify-center items-center flex my-[16px] h-[28px],
                      ${
                            [0, 1, 2].includes(order.status)
                                ? "text-[#FFF]"
                                : "text-sold_out_gray"
                        }`
                    )}
                >
                    {order.order_time || "-"}
                </p>
            </Grid>
        </>
    )
}

export default OrderRaw ;
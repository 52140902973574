import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, TextField, Button, Grid } from "@mui/material";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useGlobalOrder } from "../../utils/GlobalStateContext";
function Member(props) {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    mobile: "",
    address: "",
    get_time: "",
    note: "",
  });
  const { open, handleClose, setValue, control, getValues, submitOrder } = props;
  const {toast} = useGlobalOrder();
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  // 快選按鈕的處理
  const handleQuickSelect = (minutes) => {
    const newGetTime = dayjs()
      .add(minutes, "minute")
      .format("YYYY-MM-DDTHH:mm");
    setFormData((prevState) => ({
      ...prevState,
      get_time: newGetTime,
    }));
  };


  const [backdropClickCount, setBackdropClickCount] = useState(0);

  const handleBackdropClick = () => {
    setBackdropClickCount((prevCount) => prevCount + 1);
  };

  const resetDialogs = (event,reason) => {
    if (reason === "backdropClick") {
      handleBackdropClick() ;
      if(backdropClickCount > 0){
        setBackdropClickCount(0);
        handleClose();
      }
    }
  };

  const handleSaveMember = () => {
    // 将 formData 中的每个字段更新到表单中
    Object.keys(formData).forEach((key) => {
      setValue(key, formData[key]); // 使用 setValue 更新每个字段
    });
    getValues("buycart").length > 0 && submitOrder();
    toast.success('修改成功');
    handleClose();
  };
  useEffect(() => {
    setFormData({
      name: getValues("name"),
      number: getValues("number"),
      mobile: getValues("mobile"),
      address: getValues("address"),
      get_time: getValues("get_time"),
      note: getValues("note"),
    });
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={resetDialogs}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 4, // 設定對話框的圓角
          backgroundColor: "#E2E2E2", // 設定對話框背景顏色
          padding: "32px 24px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              姓名
            </p>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  {...field}
                  onChange={handleChange}
                  value={formData.name}
                  InputProps={{
                    sx: {
                      // 这里可以修改 .MuiInputBase-input 的样式
                      borderRadius: 4, // 設定圓角
                      fontSize: "20px",
                      color: "#797777",
                      fontWeight: 700,
                      backgroundColor: "#FFF",
                      "&::placeholder": {
                        color: "#C0C0C0", // 自定义占位符颜色
                      },
                      "&::-webkit-calendar-picker-indicator": {
                        display: "none", // 隐藏日历图标
                      },
                    },
                  }}
                  sx={{
                    borderRadius: 4, // 設定圓角
                    backgroundColor: "white", // 設定背景顏色為白色
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // 去除邊框
                      },
                      "&:hover fieldset": {
                        border: "none", // 去除懸停時邊框
                      },
                      "&.Mui-focused fieldset": {
                        border: "none", // 去除獲得焦點時邊框
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              桌號單號
            </p>
            <Controller
              name="number"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  onChange={handleChange}
                  value={formData.number}
                  InputProps={{
                    sx: {
                      // 这里可以修改 .MuiInputBase-input 的样式
                      borderRadius: 4, // 設定圓角
                      fontSize: "20px",
                      color: "#797777",
                      fontWeight: 700,
                      backgroundColor: "#FFF",
                      "&::placeholder": {
                        color: "#C0C0C0", // 自定义占位符颜色
                      },
                      "&::-webkit-calendar-picker-indicator": {
                        display: "none", // 隐藏日历图标
                      },
                    },
                  }}
                  sx={{
                    borderRadius: 4, // 設定圓角
                    backgroundColor: "white", // 設定背景顏色為白色
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // 去除邊框
                      },
                      "&:hover fieldset": {
                        border: "none", // 去除懸停時邊框
                      },
                      "&.Mui-focused fieldset": {
                        border: "none", // 去除獲得焦點時邊框
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              手機號碼
            </p>
            <Controller
              name="mobile"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  onChange={handleChange}
                  value={formData.mobile}
                  type="number"
                  InputProps={{
                    sx: {
                      // 这里可以修改 .MuiInputBase-input 的样式
                      borderRadius: 4, // 設定圓角
                      fontSize: "20px",
                      color: "#797777",
                      fontWeight: 700,
                      backgroundColor: "#FFF",
                      "& input[type='number']": {
                        appearance: "none",
                        MozAppearance: "textfield", // For Firefox
                      },
                      "& input::-webkit-outer-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                      "& input::-webkit-inner-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    },
                  }}
                  sx={{
                    borderRadius: 4, // 設定圓角
                    backgroundColor: "white", // 設定背景顏色為白色
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // 去除邊框
                      },
                      "&:hover fieldset": {
                        border: "none", // 去除懸停時邊框
                      },
                      "&.Mui-focused fieldset": {
                        border: "none", // 去除獲得焦點時邊框
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              地址
            </p>
            <Controller
              name="address"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  onChange={handleChange}
                  value={formData.address}
                  InputProps={{
                    sx: {
                      // 这里可以修改 .MuiInputBase-input 的样式
                      borderRadius: 4, // 設定圓角
                      fontSize: "20px",
                      color: "#797777",
                      fontWeight: 700,
                      backgroundColor: "#FFF",
                      "&::placeholder": {
                        color: "#C0C0C0", // 自定义占位符颜色
                      },
                      "&::-webkit-calendar-picker-indicator": {
                        display: "none", // 隐藏日历图标
                      },
                    },
                  }}
                  sx={{
                    borderRadius: 4, // 設定圓角
                    backgroundColor: "white", // 設定背景顏色為白色
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // 去除邊框
                      },
                      "&:hover fieldset": {
                        border: "none", // 去除懸停時邊框
                      },
                      "&.Mui-focused fieldset": {
                        border: "none", // 去除獲得焦點時邊框
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              取餐時間
            </p>
            <Controller
              name="get_time"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  type="datetime-local"
                  fullWidth
                  InputProps={{
                    sx: {
                      // 这里可以修改 .MuiInputBase-input 的样式
                      borderRadius: 4, // 設定圓角
                      fontSize: "20px",
                      color: "#797777",
                      fontWeight: 700,
                      backgroundColor: "#FFF",
                      "&::placeholder": {
                        color: "#C0C0C0", // 自定义占位符颜色
                      },
                      "&::-webkit-calendar-picker-indicator": {
                        display: "none", // 隐藏日历图标
                      },
                    },
                  }}
                  value={formData.get_time}
                  onChange={handleChange}
                  sx={{
                    borderRadius: 4, // 設定圓角
                    backgroundColor: "white", // 設定背景顏色為白色
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // 去除邊框
                      },
                      "&:hover fieldset": {
                        border: "none", // 去除懸停時邊框
                      },
                      "&.Mui-focused fieldset": {
                        border: "none", // 去除獲得焦點時邊框
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>

          {/* 快選按鈕部分 */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              {" "}
            </p>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    onClick={() => handleQuickSelect(10)}
                    sx={{
                      borderRadius: "9999px",
                      backgroundColor: "#B0BEC5",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#8E8E8E",
                      },
                    }}
                  >
                    10分鐘
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    onClick={() => handleQuickSelect(15)}
                    sx={{
                      borderRadius: "9999px",
                      backgroundColor: "#B0BEC5",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#8E8E8E",
                      },
                    }}
                  >
                    15分鐘
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    onClick={() => handleQuickSelect(20)}
                    sx={{
                      borderRadius: "9999px",
                      backgroundColor: "#B0BEC5",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#8E8E8E",
                      },
                    }}
                  >
                    20分鐘
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    onClick={() => handleQuickSelect(30)}
                    sx={{
                      borderRadius: "9999px",
                      backgroundColor: "#B0BEC5",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#8E8E8E",
                      },
                    }}
                  >
                    30分鐘
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              備註
            </p>
            <Controller
              name="note"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  rows={4}
                  value={formData.note}
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      // 这里可以修改 .MuiInputBase-input 的样式
                      borderRadius: 4, // 設定圓角
                      fontSize: "20px",
                      color: "#797777",
                      fontWeight: 700,
                      backgroundColor: "#FFF",
                      "&::placeholder": {
                        color: "#C0C0C0", // 自定义占位符颜色
                      },
                      "&::-webkit-calendar-picker-indicator": {
                        display: "none", // 隐藏日历图标
                      },
                    },
                  }}
                  sx={{
                    borderRadius: 4, // 設定圓角
                    backgroundColor: "white", // 設定背景顏色為白色
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // 去除邊框
                      },
                      "&:hover fieldset": {
                        border: "none", // 去除懸停時邊框
                      },
                      "&.Mui-focused fieldset": {
                        border: "none", // 去除獲得焦點時邊框
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            marginTop: "32px",
          }}
        >
          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={{
              backgroundColor: "#FFF",
              height: "80px",
              borderRadius: 7,
              padding: "8px 16px",
              boxShadow: "unset",
            }}
            onClick={handleSaveMember}
          >
            <p className="text_default_style text-dark_red font-[700]">儲存</p>
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default Member;

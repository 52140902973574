import { useState, useEffect } from "react";

const useCurrentTime = () => {
    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            const formattedDate = now.toISOString().split("T")[0].replace(/-/g, "/");
            const options = {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
            };
            const formattedTime = now.toLocaleTimeString([], options);
            setCurrentTime(`${formattedDate} ${formattedTime}`);
        };

        updateTime();
        const interval = setInterval(updateTime, 1000);
        return () => clearInterval(interval);
    }, []);

    return currentTime;
};

export default useCurrentTime;

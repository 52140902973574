import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // 更新 state 以便下一個 render 顯示 fallback UI
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // 你可以將錯誤信息記錄到錯誤報告服務
        console.error("Caught error:", error, errorInfo);
    }

    handleReload = () => {
        // 重新整理頁面
        window.location.reload();
    };

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <h2>抱歉，發生錯誤！</h2>
                    <button onClick={this.handleReload}>重新整理</button>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

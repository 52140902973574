import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Button, Grid, Typography } from "@mui/material";
import Cash from "./Payments/Cash.tsx";
import CreditCard from "./Payments/CreditCard.tsx";
import Delivery from "./Payments/Delivery.tsx";
import LinePay from "./Payments/LinePay.tsx";

// 定義支付方式
export const paymentMethods = [
    { title: "現金", id: 1 },
    { title: "LinePay", id: 2 },
    { title: "信用卡", id: 3 },
    { title: "Uber Eats", id: 4 },
    { title: "Food Panda", id: 5 },
];

function Payment(props) {
  // 狀態管理，用於切換顯示的內容
  const {
    open,
    handleClose,
    getValues,
    clickCheckOut,
    setValue,
    amount_paid,
    change_amount,
    order_total,
    unpaid_amount,
    selectedPaymentMethod,
    setSelectedPaymentMethod
  } = props;

  const handlePaymentClick = (value) => {
    setSelectedPaymentMethod(value);
    setValue("pay_method", value);
  };
  // 渲染金額顯示
  const renderAmountDisplay = () => {
    return (
      <>
        <div>
          <h6 className="mb-[16px] text_default_style text-sold_out_gray font-[700]">
            訂單金額
          </h6>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "#FFF",
              borderRadius: 4,
              height: "80px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <p className="system_login_title text-[#000] font-[700]">
              {order_total}
            </p>
          </Typography>
        </div>
        <div>
          <h6 className="mb-[16px] text_default_style text-sold_out_gray font-[700]">
            已付金額
          </h6>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "#FFF",
              borderRadius: 4,
              height: "80px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <p className="system_login_title text-[#000] font-[700]">
              {amount_paid}
            </p>
          </Typography>
        </div>
        <div>
          <h6 className="mb-[16px] text_default_style text-sold_out_gray font-[700]">
            {unpaid_amount === 0 ? "找零金額" : "未付金額"}
          </h6>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "#FFF",
              borderRadius: 4,
              height: "80px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <p className="system_login_title text-[#000] font-[700]">
              {unpaid_amount === 0 ? change_amount : unpaid_amount}
            </p>
          </Typography>
        </div>
      </>
    );
  };

  // 渲染支付按鈕
  const renderPaymentButtons = () =>
    paymentMethods.map((method, index) => (
      <Button
        key={index}
        variant="outlined"
        fullWidth
        onClick={() => {
          handlePaymentClick(method.id);
        }}
        sx={{
          borderRadius: 4,
          border: "none",
          display: "flex",
          justifyContent: "center",
          bgcolor: "#838383",
          height: "64px",
          alignItems: "center",
          paddingX: "16px",
          marginBottom: "16px",
        }}
      >
        <p className="text_default_style text-[#FFF]">{method.title}</p>
      </Button>
    ));

  // 渲染選擇的支付方式畫面
  const renderPaymentMethodScreen = () => {
    const selectedMethod = paymentMethods.find(
      (method) => method.id === selectedPaymentMethod
    );

    // 判斷 selectedMethod 的值，並返回相應的組件
    switch (selectedMethod?.id) {
      case 1:
        return (
          <Cash
            back={() => setSelectedPaymentMethod(0)}
            clickCheckOut={clickCheckOut}
            setValue={setValue}
            getValues={getValues}
          />
        ); // 現金組件
      case 2:
        return (
          <LinePay
            back={() => setSelectedPaymentMethod(0)}
            unpaid_amount={unpaid_amount}
            clickCheckOut={clickCheckOut}
          />
        ); // LinePay 組件
      case 3:
        return (
          <CreditCard
            clickCheckOut={clickCheckOut}
            unpaid_amount={unpaid_amount}
          />
        ); // 信用卡組件
      case 4:
        return (
          <Delivery
            platForm={"uber"}
            clickCheckOut={clickCheckOut}
            unpaid_amount={unpaid_amount}
          />
        ); // UberEats 組件
      case 5:
        return (
          <Delivery
            platForm={"panda"}
            clickCheckOut={clickCheckOut}
            unpaid_amount={unpaid_amount}
          />
        ); // FoodPanda 組件
      default:
        return (
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            請返回
          </Typography>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"md"}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 4,
          backgroundColor: "#E2E2E2",
          padding: "32px 24px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Grid container direction={"row"} spacing={2}>
          <Grid
            item
            xs={7}
            onClick={() => selectedPaymentMethod && setSelectedPaymentMethod(0)}
            sx={{
              justifyContent: "center",
              padding: "16px",
              border: "none",
              borderRight: "1px solid #FFF",
            }}
          >
            {renderAmountDisplay()}
          </Grid>

          <Grid
            item
            xs={5}
            sx={{
              display: "grid",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {unpaid_amount <= 0 ? (
              <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
                支付完成
              </h1> // 顯示支付完成的文字
            ) : selectedPaymentMethod ? (
              renderPaymentMethodScreen() // 顯示選擇的支付方式畫面
            ) : (
              renderPaymentButtons() // 顯示支付按鈕
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default Payment;

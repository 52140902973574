// DiningOut.jsx
import React, { useState, useEffect, useRef} from "react";
import {
    Button,
    ButtonGroup,
    Grid,
    Typography,
} from "@mui/material";
import {getOrderStatusAPI, postOrderStatusAPI, printOrder , getIncomeAPI} from "../../utils/ordersControlar.js";
import { useGlobalOrder } from "../../utils/GlobalStateContext.jsx";
import TodayIncome from "./Tab/TodayIncome";
import ProductIncome from "./Tab/ProductIncome";
import SettingTab from "./Tab/SettingTab";

function Income() {

    const [tab, setTab] = useState(1);
    const { systemData, setSystemData, setGlobalOrder } = useGlobalOrder();

    const orderTypes = [
        { label: "營業數據", value: 1 },
        { label: "商品數據", value: 2 },
        { label: "相關設定", value: 3 },
    ];

  return (
      <Grid
          container
          direction="column"
          sx={{ backgroundColor: "#f0f0f0", height: "100vh" }}
      >
          {/* 上方ButtonGroup区域，用于选择订单类型 */}
          <Grid
              item
              sx={{
                  marginX: "0px !important",
                  width: "100%",
                  overflow: "auto",
                  height: 80,
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 16px",
                  gap: "16px",
              }}
              className="hide-scrollbar"
          >
              <ButtonGroup
                  sx={{
                      overflow: "hidden",
                      height: "48px",
                  }}
                  aria-label="Order type selection"
              >
                  {orderTypes.map((type) => (
                      <Button
                          key={type.value}
                          sx={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              px: 3,
                              border: "1px solid #8E8E8E",
                              borderRadius: 4,
                              borderColor: tab === type.value ? "#FFF" : "#8E8E8E",
                              backgroundColor:
                                  tab === type.value ? "#FFF" : "#8E8E8E",
                              color: tab === type.value ? "#A43B3B" : "#FFF",
                          }}
                          onClick={() => setTab(type.value)}
                      >
                          <p className="flex min-w-[36px]">{type.label}</p>
                      </Button>
                  ))}

                  {
                      systemData.showMoneyBox ? (
                          <>
                              <Button
                                  key="開啟錢櫃"
                                  // disabled={!memberLogin}
                                  onClick={() => {
                                      printOrder({orderId: 0, type: 4})
                                  }}
                                  sx={{
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      px: 3,
                                      border: "1px solid #8E8E8E",
                                      borderRadius: 4,
                                      borderColor: "#8E8E8E",
                                      backgroundColor: "#8E8E8E",
                                      color: "#FFF",
                                  }}
                              >
                                  <p className="flex min-w-[36px]">開啟錢櫃</p>
                              </Button>
                          </>
                      ) : ''
                  }
              </ButtonGroup>
          </Grid>

          {/* 下方区域 */}
          <Grid
              container
              direction="column"
              sx={{
                  maxHeight: "calc(100% - 80px)",
                  flexGrow: 1,
                  display: "flex",
                  flexWrap: "nowrap",
                  borderTop: "1px solid #797777",
              }}
          >
              <Grid
                  container
                  sx={{
                      gap: 2,
                      height: "100%",
                      overflowY: "auto",
                      flexWrap: "wrap",
                      padding: 2,
                      justifyContent: "flex-start", // 將卡片靠左對齊
                  }}
                  className="hide-scrollbar"
              >
                  { tab === 1 ? (<TodayIncome />) : ( tab === 2 ? <ProductIncome /> : <SettingTab /> ) }
              </Grid>
          </Grid>
      </Grid>
  ) ;
}

export default Income;
